import { initPulse } from '../../core/js/pulse/pulse-init.js';

const useImpressions = true;

const extendedConfig = {
  enableImpressions: useImpressions,
  skipInitalPageView:
    location.pathname === '/oslo' || location.pathname === '/foreldreliv',
  trackTeaserMetrics: true,
};

export const initApPulse = () => {
  initPulse(extendedConfig);
};
