import type { PulseAttributes } from './pulse-util.js';
import type { PulseConfigOptions } from './pulse-v4.js';

import { getTeaserMetricsObject } from '../../../../src/core/mandatory-integrations/pulse/utils/metrics.js';
import { fasten } from '../frontend-api.js';
import {
  createPulseObject,
  createPulseProvider,
  createPulseTarget,
  getDrEditionProps,
  getPulseMeta,
} from './pulse-util.js';

const visibilityTracking = (options?: PulseConfigOptions): void => {
  function getViewportRect() {
    return {
      top: 0,
      left: 0,
      right: window.innerWidth || 0,
      bottom: window.innerHeight || 0,
    };
  }

  function isMostlyInViewport(element) {
    const viewportRect = getViewportRect();
    const elementRect = element.getBoundingClientRect();
    const heightAdjustment = (elementRect.bottom - elementRect.top) * 0.33;
    const widthAdjustment = (elementRect.right - elementRect.left) * 0.33;

    return (
      elementRect.width > 0 &&
      elementRect.height > 0 &&
      elementRect.left + widthAdjustment >= viewportRect.left &&
      elementRect.right - widthAdjustment <= viewportRect.right &&
      elementRect.top + heightAdjustment >= viewportRect.top &&
      elementRect.bottom - heightAdjustment <= viewportRect.bottom
    );
  }

  /**
   * Check if element covers one third of the viewport
   *
   * @param {Object} elementRect
   * @param {Object} viewportRect
   * @param {number} oneThirdViewportHeight
   * @return {boolean}
   */
  function coversOneThirdOfViewport(element) {
    const viewportRect = getViewportRect();
    const oneThirdViewportHeight =
      (viewportRect.bottom - viewportRect.top) * 0.33;
    const elementRect = element.getBoundingClientRect();

    return (
      elementRect.width > 0 &&
      elementRect.left >= viewportRect.left &&
      elementRect.right <= viewportRect.right &&
      ((elementRect.top >= viewportRect.top &&
        elementRect.top <= viewportRect.bottom &&
        viewportRect.bottom - elementRect.top >= oneThirdViewportHeight) ||
        (elementRect.bottom >= viewportRect.top &&
          elementRect.bottom <= viewportRect.bottom &&
          elementRect.bottom - viewportRect.top >= oneThirdViewportHeight) ||
        (elementRect.top <= viewportRect.top &&
          elementRect.bottom >= viewportRect.bottom))
    );
  }

  type trackerConfig = {
    publicationCode: string;
    pageId: string;
    pageType: string;
  };
  class VisibilityTrackingPlugin {
    interval = 500;
    query = '[data-pulse-entity-id]';
    reportImmediately = true;
    tracker;
    config: trackerConfig;
    seenElements: { [id: string]: PulseAttributes };
    elementsToReport: PulseAttributes[];
    reportInteval: ReturnType<typeof setTimeout>;
    isFindingElements = false;
    throttledFindNewElementsInViewportBinding: () => void;

    constructor(tracker, config: trackerConfig) {
      this.tracker = tracker;
      this.config = config;
      this.throttledFindNewElementsInViewportBinding = () =>
        this.throttledFindNewElementsInViewport();
      this.start(this.reportImmediately);
    }
    throttledFindNewElementsInViewport() {
      if (!this.isFindingElements) {
        // throttle to running max every 500ms
        window.setTimeout(() => {
          this.findNewElementsInViewport();
          this.isFindingElements = false;
        }, 500);

        this.isFindingElements = true;
      }
    }

    start(reportImmediately = true) {
      this.seenElements = {};
      this.elementsToReport = [];
      this.reportInteval = setInterval(
        () => this.reportVisibility(),
        this.interval,
      );
      this.bind();
      this.findNewElementsInViewport();
      if (reportImmediately) {
        this.reportVisibility();
      }
    }

    stop() {
      clearInterval(this.reportInteval);
      this.unbind();
    }

    findNewElementsInViewport() {
      const potentialElements = document.querySelectorAll(this.query);
      Array.prototype.forEach.call(potentialElements, (element) => {
        const pulseMeta = getPulseMeta(element);
        const id = pulseMeta.entityId;

        if (
          !this.seenElements[id] &&
          (isMostlyInViewport(element) || coversOneThirdOfViewport(element))
        ) {
          this.seenElements[id] = pulseMeta;
          this.elementsToReport.push(pulseMeta);
        }
      });
    }

    reportVisibility() {
      this.elementsToReport.forEach((pulseMeta) => {
        const target = createPulseTarget(
          pulseMeta,
          this.config.publicationCode,
        );

        const object = createPulseObject(
          pulseMeta,
          this.config.publicationCode,
          this.config.pageId,
          this.config.pageType,
        );

        const provider = createPulseProvider(pulseMeta);

        const experiments =
          pulseMeta.experiments && pulseMeta.experiments.length
            ? [...(window.PULSE_EXPERIMENTS || []), ...pulseMeta.experiments]
            : undefined;

        const device = fasten.siteVersion();

        const drEditionPulseData = getDrEditionProps(pulseMeta, device, 'View');

        let teaserMetrics = {};
        if (options?.trackTeaserMetrics) {
          const metrics = getTeaserMetricsObject(
            window.CURATE_CONFIG?.variant,
            pulseMeta,
          );
          if (metrics) teaserMetrics = metrics;
        }

        const data = {
          object,
          target,
          provider,
          experiments,
          ...teaserMetrics,
          ...drEditionPulseData,
        };

        this.tracker.track('trackerEvent', data);
      });
      this.elementsToReport = [];
    }

    bind() {
      window.addEventListener(
        'scroll',
        this.throttledFindNewElementsInViewportBinding,
      );
      window.addEventListener(
        'resize',
        this.throttledFindNewElementsInViewportBinding,
      );
    }

    unbind() {
      window.removeEventListener(
        'scroll',
        this.throttledFindNewElementsInViewportBinding,
      );
      window.removeEventListener(
        'resize',
        this.throttledFindNewElementsInViewportBinding,
      );
    }

    restart() {
      this.stop();
      this.start();
    }
  }
  //global pulse
  window.pulse('provide', 'visibilityTracking', VisibilityTrackingPlugin);
};

export const initPulseImpressionAddon = (
  options?: PulseConfigOptions,
): void => {
  visibilityTracking(options);
};
