import { initApPulse } from '../js/init-pulse.js';
import { isUserConsent } from '../../core/js/user-consents.js';
import { provideCoreHeaderSessionAdapter } from '../../core/js/schibsted-account/core-header-adapter.js';
import { initUserSession } from '../../core/js/schibsted-account/index.server.js';
import { userDataStoreApi } from '../../core/js/user-data-store/api.client.js';

window.isUserConsent = isUserConsent;
window.userDataStoreApi = userDataStoreApi;

initUserSession();
provideCoreHeaderSessionAdapter();
initApPulse();

window.addEventListener('load', () => {
  Promise.all([
    import('../js-chunks/on-load.js'),
    import('../js-chunks/ads.js'),
    import('../js-chunks/braze.js'),
  ]).catch((e) => console.error(e));
});
